<!--提现申请-->
<template>
  <div style="padding: 20px;">
    <div>
      <div style="display: flex;align-items: center;justify-content: space-between;">
        <span style="font-size: 24px;font-weight: 500;color: #707070;">提现申请</span>
        <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
      </div>

      <div style="width: 80%;margin: auto;margin-top: 30px;">
        <el-form ref="form" :model="form" label-width="100px">
          <el-card shadow="never">
            <div style='width: 50%;margin: auto;'>
              <div style="text-align: center;margin-top: 20px;">
                <span style="font-size: 30px;font-weight: 500;color: #000000;">提现申请</span>
              </div>
              <el-form-item label="可提现金额：">
                <span style="font-size: 18px;font-weight: 700;color: #ff9b05;">3000元</span>
              </el-form-item>
              <el-form-item label="支付方式：">
                <div style="display: flex;align-items: center;">
                  <img src="@/assets/images/home/gerenzhongxin/weixin.png" alt="" style="width: 24px;height: 24px;margin-right: 10px;">
                  <span style="font-size: 18px;font-weight: 500;color: #202033;">微信支付</span>
                </div>
              </el-form-item>
              <el-form-item label="收款账户：">
                <span style="font-size: 18px;font-weight: 500;color: #202033;">孟春十二</span>
              </el-form-item>
              <el-form-item label="提现金额：">
                <div style="display: flex;align-items: center;">
                  <el-input
                      style="width: 240px!important;"
                      clearable
                      v-model="form.facilitator_name"
                      placeholder="请输入提现金额"/>
                  <span style="font-size: 14px;font-weight: 400;color: #ff9b05;margin-left: 20px;cursor: pointer;">全部提现</span>
                </div>
              </el-form-item>
              <el-form-item label="是否开票：">
                <el-switch v-model="form.value"></el-switch>
                <el-button style="border-color: #E4E5E9!important;background: #FFFFFF!important;margin-left: 20px;">上传发票</el-button>
              </el-form-item>
              <el-form-item label="交易密码：">
                <div style="display: flex;align-items: center;">
                  <el-input
                      style="width: 240px!important;"
                      clearable
                      v-model="form.facilitator_name"
                      placeholder="请输入交易密码"/>
                  <span style="font-size: 14px;font-weight: 400;color: #7E7F8C;margin-left: 20px;cursor: pointer;">忘记密码？</span>
                </div>
              </el-form-item>
            </div>
          </el-card>
        </el-form>

      </div>
    </div>

    <div style="margin-top: 50px;text-align: center;">
      <el-button type="warning" style="width: 200px !important; margin-left: 20px;">提交</el-button>
      <el-button style="width: 200px !important;">取消</el-button>
    </div>

  </div>
</template>

<script>
  export default {
    data(){
      return{
        form:{},

      }
    },

    methods:{
      //点击返回按钮
      returnPage(){
        this.$router.go(-1);
        localStorage.setItem('showDetails', 'true')
      },

    },

  }
</script>

<style scoped lang="scss">

</style>
